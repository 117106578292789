@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans/OpenSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans/OpenSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans/OpenSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('../fonts/myriad/MYRIADPRO-REGULAR.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('../fonts/myriad/MYRIADPRO-COND.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('../fonts/myriad/MYRIADPRO-CONDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('../fonts/myriad/MyriadPro-Light.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('../fonts/myriad/MYRIADPRO-SEMIBOLD.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('../fonts/myriad/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('../fonts/myriad/MYRIADPRO-BOLDCOND.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('../fonts/myriad/MYRIADPRO-BOLD.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('../fonts/myriad/MYRIADPRO-BOLDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('../fonts/myriad/MYRIADPRO-BOLDCONDIT.woff') format('woff');
}