html {
  scroll-behavior: smooth;
}

body {
  font-family: "Myriad Pro Light", sans-serif;
  background: #222;
}

.swiper-button-prev, .swiper-button-next{
  border-color: #f1f1f1 !important;
  color: #f1f1f1 !important;
}